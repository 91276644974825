import { Component, ElementRef, EventEmitter, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import * as XLSX from 'xlsx';
import { PaginatorPageNumberButtonDirective } from './paginator-page-number-button.directive';
import { NavigationExtras, Router } from '@angular/router';
import { DeviceRecommendationsService } from 'src/app/core/services/device-recommendations/device-recommendations.service';
import { HttpError } from 'src/app/core/types/http/error';
import { CurrencyPipe } from '@angular/common';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-device-recommendation-table',
  templateUrl: './device-recommendation-table.component.html',
  styleUrls: ['./device-recommendation-table.component.less']
})
export class DeviceRecommendationTableComponent {
  @ViewChild(PaginatorPageNumberButtonDirective, ({ static: true })) paginator!: PaginatorPageNumberButtonDirective;
  totalRecords: number = 0;
  pageSize: number = 25;
  lastUpdateTs: Date | undefined;
  isLoading = false;
  isSuccess: boolean = false;
  dataSource = new MatTableDataSource;
  data: any[] = [];
  excelData: any[] = [];
  @ViewChild(MatSort)
  sort: MatSort = new MatSort;
  @ViewChild('table', { read: ElementRef })
  table!: ElementRef;
  pageIndex = 0;
  goTo: string = '';
  is25PageSizeButtonDisabled: boolean = true;
  is50PageSizeButtonDisabled: boolean = false;
  is100PageSizeButtonDisabled: boolean = false;
  selectedRow: any;
  successMsg = 'The device has been updated successfully.'
  errorMsg = 'Unable to retrieve data at this time. Please try again.';
  isError = false;
  displayColumns: string[] = ['state', 'policyType', 'coverageALimit',
    'deviceType', 'vendor', 'deviceOffer', 'deviceSavingsPct', 'deviceType2', 'vendor2',
    'deviceOffer2', 'deviceSavingsPct2', 'Actions'];
  @ViewChild('duplicateModalRef', { static: false }) duplicateModalRef!: ElementRef;
  @ViewChild('deleteModalRef', { static: false }) deleteModalRef!: ElementRef;
  @Output() page = new EventEmitter<PageEvent>();

  constructor(private readonly router: Router,
    private readonly deviceRecommendationsService: DeviceRecommendationsService,
    private currencyPipe: CurrencyPipe
  ) {
    if (this.router.getCurrentNavigation()?.extras?.state?.['isSuccess']) {
      this.isSuccess = true;
      this.successMsg = this.router.getCurrentNavigation()?.extras?.state?.['successMsg'];
    }

  }

  ngOnInit(): void {
    this.getTableData();
    this.dataSource.paginator = this.paginator.matPag;
  }

  async getTableData() {
    const scrubbedMap: any = new Map<string, Object>([]);
    await this.deviceRecommendationsService.getDeviceRecommendationDetails().then((response: any[]) => {
      if (response?.length) {
        response.forEach(deviceData => {
          let key = deviceData.state + "-" + deviceData.policyType + "-" + deviceData.coverageALimit;
          if (scrubbedMap.get(key)) {
            let obj = scrubbedMap.get(key);
            if (deviceData?.deviceOption === 1) {
              scrubbedMap.set(key, {
                ...scrubbedMap.get(key),
                deviceType: deviceData.deviceType,
                vendor: deviceData.vendor,
                deviceOffer: deviceData.deviceOffer,
                deviceSavingsPct: deviceData.deviceSavingsPct,
              })
            }
            else if (deviceData?.deviceOption === 2) {
              scrubbedMap.set(key, {
                ...scrubbedMap.get(key),
                deviceType2: deviceData.deviceType,
                vendor2: deviceData.vendor,
                deviceOffer2: deviceData.deviceOffer,
                deviceSavingsPct2: deviceData.vendor ? deviceData.deviceSavingsPct : '',
                deviceRecommendationSeqId2: deviceData.deviceRecommendationSeqId
              });
            }
          }
          else {
            if (deviceData?.deviceOption === 1) {
              scrubbedMap.set(key, {
                ...deviceData,
                deviceType: deviceData.deviceType,
                vendor: deviceData.vendor,
                deviceOffer: deviceData.deviceOffer,
                deviceSavingsPct: deviceData.deviceSavingsPct,
                deviceType2: '',
                vendor2: '',
                deviceOffer2: '',
                deviceSavingsPct2: '',
                deviceRecommendationSeqId2: ''
              });
            }
            if (deviceData?.deviceOption === 2) {
              scrubbedMap.set(key, {
                ...deviceData,
                deviceType2: deviceData.deviceType,
                vendor2: deviceData.vendor,
                deviceOffer2: deviceData.deviceOffer,
                deviceSavingsPct2: deviceData.vendor ? deviceData.deviceSavingsPct : '',
                deviceRecommendationSeqId2: deviceData.deviceRecommendationSeqId2,
                deviceType: '',
                vendor: '',
                deviceOffer: '',
                deviceSavingsPct: ''
              });
            }

          }
          // Return unchanged object if no conditions match
        });
        this.data = Array.from(scrubbedMap.values());
      }
      this.dataSource.data = this.data;
      this.totalRecords = this.data?.length;
      this.isLoading = false;
    }).catch((error: HttpError) => {
      this.isError = true;
      this.showNoData();
    });
  }
  formatPercentage(value: number) {
    return value || value === 0 ? value.toString() + "%" : ''
  }
  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator.matPag;
  }

  ngOnChanges(): void {
    this.dataSource.paginator = this.paginator.matPag;
  }

  openDeleteModel(row: any): void {
    this.selectedRow = row;
    this.deleteModalRef.nativeElement.openModal();
  }

  cancelDelete() {
    this.deleteModalRef.nativeElement.closeModal();
  }
  async deleteDevice() {
    await this.deviceRecommendationsService.deleteDeviceRecmmendationDetails(this.selectedRow.deviceRecommendationSeqId).then((response: any[]) => {
      this.onDeleteSuccess();

    }).catch((error) => {
      if (error?.status === 200) {
        this.onDeleteSuccess();
      } else {
        this.errorMsg = ' The system is experiencing an error and the device has not been deleted. Please try again.';
        this.isError = true;
        this.isSuccess = false;
      }
    });
  }

  onDeleteSuccess() {
    const index = this.data.findIndex((data) => data.deviceRecommendationSeqId === this.selectedRow.deviceRecommendationSeqId);
    this.data?.splice(index, 1);
    this.dataSource.data = this.data;
    this.totalRecords = this.data?.length;
    this.successMsg = 'The device has been deleted.';
    this.isSuccess = true;
    this.isError = false;
  }
  openDuplicateModal(row: any): void {
    this.selectedRow = row;
    this.duplicateModalRef.nativeElement.openModal();
  }

  editDuplicateRow(): void {
    this.router.navigate(['/admin/device-edit'], {
      state: { selectedRow: this.selectedRow, tableData: [...this.data], isDuplicateFlow: true }
    })
  }

  exportToExcel() {
    this.dataSource.data.forEach((tableData: any) => {
      this.excelData.push({
        'State': tableData.state,
        'Policy': tableData.policyType,
        'Coverage A Below': this.currencyPipe.transform(tableData.coverageALimit, 'USD', 'symbol', '1.0-0'),
        'Device Type 1': tableData.deviceType,
        'Device Vendor 1': tableData.vendor,
        'Device Offer 1': tableData.deviceOffer,
        'Device Savings 1': this.formatPercentage(tableData.deviceSavingsPct),
        'Device Type 2': tableData.deviceType2,
        'Device Vendor 2': tableData.vendor2,
        'Device Offer 2': tableData.deviceOffer2,
        'Device Savings 2': this.formatPercentage(tableData.deviceSavingsPct2),
      });
    });
    const sheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.excelData);
    const book: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(book, sheet, 'Device');
    XLSX.writeFile(book, 'DeviceData.xlsx')
  }

  sortData($event: any) {
    const sortId = $event.active;
    const sortDirection = $event.direction;
    if ('asc' == sortDirection) {
      this.dataSource.data = this.data.slice().sort(
        (a, b) => a[sortId] > b[sortId] ? -1 : this.sortASC(a, b, sortId)
      );
    } else {
      this.dataSource.data = this.data.slice().sort(
        (a, b) => a[sortId] < b[sortId] ? -1 : this.sortDESC(a, b, sortId)
      );
    }
  }

  sortASC(a: any, b: any, sortId: any) {
    return a[sortId] < b[sortId] ? 1 : 0;
  }

  sortDESC(a: any, b: any, sortId: any) {
    return a[sortId] > b[sortId] ? 1 : 0;
  }

  onEditClick(selectedRow: any) {
    this.router.navigate(['/admin/device-edit'], { state: { selectedRow: selectedRow, tableData: [...this.data] } });
  }

  setFirstPageSize(): void {
    this.pageSize = 25;
    this.paginator.matPag.pageSize = this.pageSize;
    this.is25PageSizeButtonDisabled = true;
    this.is50PageSizeButtonDisabled = false;
    this.is100PageSizeButtonDisabled = false;
    this.dataSource.paginator = this.paginator.matPag;
  }

  setSecondPageSize(): void {
    this.pageSize = 50;
    this.paginator.matPag.pageSize = this.pageSize;
    this.is25PageSizeButtonDisabled = false;
    this.is50PageSizeButtonDisabled = true;
    this.is100PageSizeButtonDisabled = false;
    this.dataSource.paginator = this.paginator.matPag;
  }

  setThirdPageSize(): void {
    this.pageSize = 100;
    this.paginator.matPag.pageSize = this.pageSize;
    this.is25PageSizeButtonDisabled = false;
    this.is50PageSizeButtonDisabled = false;
    this.is100PageSizeButtonDisabled = true;
    this.dataSource.paginator = this.paginator.matPag;
  }

  showNoData(): void {
    const dataTableEl = document.getElementById("data-table");
    const paginatorEl = document.getElementById("paginator");
    if (this.isError) {
      dataTableEl?.remove();
      paginatorEl?.remove();
    }
  }

  paginationChange(pageEvt: PageEvent): void {
    this.totalRecords = pageEvt.length;
    this.pageIndex = pageEvt.pageIndex;
    this.pageSize = pageEvt.pageSize;
    this.emitPageEvent(pageEvt);
  }

  goToChange(): void {
    const requiredButtons = Math.ceil(
      this.totalRecords / this.paginator.matPag.pageSize
    );
    if (parseInt(this.goTo) > requiredButtons) {
      this.paginator.pageIndex = requiredButtons - 1;
    } else {
      this.paginator.pageIndex = parseInt(this.goTo) - 1;
    }
    const event: PageEvent = {
      length: this.paginator.matPag.length,
      pageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize
    };
    this.paginator.matPag.page.next(event);
    this.emitPageEvent(event);
    this.goTo = '';
  }

  emitPageEvent(pageEvent: PageEvent): void {
    this.page.next(pageEvent);
  }
}
